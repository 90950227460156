.addFieldFormContainer {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.formHeading {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.fieldList {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.fieldItem {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fieldLabel {
  font-size: 1rem;
  margin-right: 10px; /* Add margin to the right of the label */
}

.addFieldButton {
  padding: 4px 6px; /* Adjusted padding for button */
  margin-left: 8px; /* Adjusted margin-right for button */
  background-color: #434e44;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addFieldButton:last-child {
  margin-right: 0; /* Remove margin-right for last button */
}

.addFieldButton:hover {
  background-color: #45a049;
}
