.createedcontainer {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
    align-items: flex-start; 
}

.badge-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.badge-name {
    font-size: 18px;
}

.edit-button, .remove-button {
    padding: 8px 16px;
    border: none;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.edit-button:hover, .remove-button:hover {
    background-color: #45a049;
}
