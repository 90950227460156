@import "~bootstrap-scss/bootstrap.scss";
@import "color";
@import "vendors/chartist/chartist";
@import "vendors/themify.scss";
@import "vendors/whether-icon.scss";
@import "vendors/icofont.scss";
@import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import "vendors/fontawesome.scss";
@import "vendors/animate/animate";
@import "vendors/sticky/sticky";
@import "vendors/todo/todo";

// Clock
@import "~react-clock/dist/Clock.css";

// Datepicker
@import "~react-datepicker/dist/react-datepicker.css";

//  ImageCrop
@import "~react-image-crop/dist/ReactCrop.css";

// Typeahead
@import "~react-bootstrap-typeahead/css/Typeahead.css";

//  Dropzone
@import "~react-dropzone-uploader/dist/styles.css";

// Drag&Drop
@import "~react-dragula/dist/dragula.css";

// @import "~@asseinfo/react-kanban/dist/styles.css";

// ReactToastify
@import "~react-toastify/dist/ReactToastify.css";

//  ImageCrop
@import "~react-image-crop/dist/ReactCrop.css";

@import "react-day-picker/dist/style.css";

//  calendar
@import "~react-big-calendar/lib/css/react-big-calendar.css";

// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/timegrid/main.css';

//  Timeline
@import "~react-vertical-timeline-component/style.min.css";

// Mde EDitor
@import "~easymde/dist/easymde.min.css";

// chat emoji
// @import "~emoji-mart/css/emoji-mart.css";

// Map
@import "~leaflet/dist/leaflet.css";

// Image gallery
@import "~react-18-image-lightbox/style.css";

//image upload
@import "~react-perfect-scrollbar/dist/css/styles.css";


.ant-table-wrapper .ant-table-pagination-right {
    justify-content: center !important;
}

/* Default styles for pagination options */
.ant-pagination .ant-pagination-options {
    display: inline-block;
    vertical-align: middle;
}

/* Media query for screens smaller than 768px (typical mobile devices) */
@media screen and (max-width: 767px) {
    .ant-pagination .ant-pagination-options {
        display: block; /* Change display to block for mobile */
        text-align: center; /* Center the pagination options */
        margin-top: 10px; /* Add some space between pagination and content */
    }
}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon~.page-body, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon~footer {
    margin-left: 90px;
    transition: .5s;
}