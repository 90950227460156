/* BadgeDesigner.css */

.badge-designer-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* flex-direction: row; */

    /* height: 100vh; */
}

.badge-settings {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
}

.badge-settings h3 {
    margin-top: 0;
    margin-bottom: 20px;
}

.badge-settings div {
    margin-bottom: 10px;
}

.badge-settings label {
    font-weight: bold;
    margin-right: 10px;
}

.badge-settings input[type="number"],
.badge-settings select,
.badge-settings input[type="checkbox"] {
    width: 100px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.badge-settings input[type="checkbox"] {
    width: auto;
}

.badge-settings select {
    width: 120px;
}

.badge-container {
    position: relative;
    border: 1px solid #ccc;
    margin-right: 20px;
}

.badge-element {
    position: absolute;
    border: 1px solid #000;
    background-color: #fff;
    cursor: move;
}

.component-settings {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 200px;
}

.component-settings h3 {
    margin-top: 0;
    margin-bottom: 20px;
}

.component-settings div {
    margin-bottom: 10px;
}

.component-settings label {
    font-weight: bold;
    margin-right: 10px;
}

.component-settings input[type="number"] {
    width: 100px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}


.add-button {
    
    margin-top: 20px; 
}

.add-button-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
}

.add-button, .preview-button, .export-button,.save-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.save-button{
    background-color: #63af4c;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px; 
}

.add-button {
    background-color: #344235;
    color: white;
}

.preview-button {
    background-color: #008CBA;
    color: white;
}

.export-button {
    background-color: #f44336;
    color: white;
}
